:root {
    --white: #ffffff;
    --black: #0c0c0c;
    --light-black: #363636;
    --absolute-black: #000000;
    --yellow: #ffd602;
    --orange: orange;
    --green: #3bca36;
    --light-green: #55d37d;
    --blue: #3d7cff;
    --purple: #7e39e6;
    --light-purple: #7f77fc;
    --light-blue: #43b0ff;
    --red: #ff3a62;
    --gray: #aeb5bc;
    --dark-gray: #8b8b8b;
    --light-gray: #e0e6ec;
    --light-gray2: #f9fcff;
    --lighter-gray: #f4f6f8; /*equal to rgba(#e0e6ec, 0.34) with white background*/ /*equal to #e0e6ec*/
    --icon-gray: #7b8a9d;
    --light-icon-gray: #9ba5b1;

    --main-theme-color: #3d7cff;
    --main-theme-color-transparent: rgba(61, 124, 255, 0.15);
    --main-theme-color-semitransparent: rgba(61, 124, 255, 0.6);
    --main-theme-color-light-transparent: rgba(224, 230, 236, 0.24);
    --main-theme-gradient: linear-gradient(96deg, #2a78e9 -5%, #5139e6 116%);
    --animation-cubic: cubic-bezier(0.4, 0, 0.2, 1);
}
